import { HttpHeaders } from '@angular/common/http';
import { NgForOf } from '@angular/common';
import { error } from '@angular/compiler/src/util';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { salaryHeadMaster, SalaryMaster } from 'src/app/models/SalaryMaster';
import { EmpsalaryslipService } from 'src/app/services/empsalaryslip.service';

@Component({
  selector: 'app-salary-head',
  templateUrl: './salary-head.component.html',
  styleUrls: ['./salary-head.component.css']
})
export class SalaryHeadComponent implements OnInit {

  @ViewChild('salaryHeadForm') salaryHeadForm: NgForm;

  // salaryHeadModel = new SalaryMaster(); // backnd 
  salaryHead = new salaryHeadMaster(); // get methon for backend to html model  
  addOrUpdate: boolean = true;
  insertSalaryhead;
  add_view_toggle: string = 'viewSalaryHead';
  public url;
  protected basePath = '/api';
  imageToupload: File | null = null;
  model: any = {};

  // Bulk upload

  excelDoc: any = {};
  fileToUpload: File | null = null;
  submitted: boolean;
  public files: any[] = [];
  fileslength: boolean;
  multipleFilePath = [];
  uploadedDocDetails = [];
  loading: boolean = false; 


  constructor(private empsalaryservice: EmpsalaryslipService) { }

  ngOnInit(): void {
    this.insertSalaryhead = new salaryHeadMaster();
    this.getSalaryDetail();
  }

  getSalaryDetail() {
    this.empsalaryservice.getSalaryDetails().subscribe(
      (data) => {
        console.log("salary details ", data)
        this.salaryHead = data.salaryHeadMaster;
        console.log("this.salaryhead ", this.salaryHead);
      }, (error) => {
        console.log(error)
      }
    )
  }

  addSalaryHeadButton() {
    this.add_view_toggle = 'addSalaryHead';
    // this.clear();
  }

  updateSalaryHead() {

    // update the exisitng head use head id 
    this.empsalaryservice.updateSalaryHeadMaster(this.insertSalaryhead).subscribe((data) => {
      this.alertmessages("Salary Head Updated sucessfully  ", "success");
      this.clear();
      this.getSalaryDetail();
      this.viewSalaryHeadButton();
    }, (error) => {
      console.log(error);
      this.alertmessages("Please Try Again", "danger");
    });

  }

  viewSalaryHeadButton() {
    this.add_view_toggle = 'viewSalaryHead';
    this.addOrUpdate = true;
    this.clear();
  }

  submit() {
    // adding new head save 
    // this.add_view_toggle = 'viewSalaryHead';
    console.log("save ", this.insertSalaryhead);

    if (this.insertSalaryhead.salaryHeadName == undefined || this.insertSalaryhead.salaryHeadName.trim().length == 0 || this.insertSalaryhead.salaryHeadName == null) {
      this.alertmessages("Please enter salary head name", "danger");
      return;
    }
    if (this.insertSalaryhead.addDeductIndic == undefined) {
      this.alertmessages("Please select type of", "danger");
      return;
    }

    if ((this.insertSalaryhead.salaryHeadName != undefined || this.insertSalaryhead.salaryHeadName.trim().length > 0) && this.insertSalaryhead.addDeductIndic != undefined) {
      this.empsalaryservice.saveSalaryHeadMaster(this.insertSalaryhead).subscribe(
        (data) => {
          console.log(data);
          this.clear();
          this.alertmessages("Salary Head added sucessfully", "success");
          this.getSalaryDetail();
          this.viewSalaryHeadButton();
        }, (error) => {
          console.log(error);
          this.alertmessages("Please Try Again", "danger");
        }
      );
    }

  }

  clear() {
    this.insertSalaryhead.salaryHeadName = '';
    this.insertSalaryhead.addDeductIndic = '';
    this.insertSalaryhead.statuatoryCode = '';
    this.salaryHeadForm.form.reset();
  }

  editSalaryHead(salarhead: any) {
    this.addOrUpdate = false;
    this.add_view_toggle = 'addSalaryHead';

    this.insertSalaryhead.salaryHeadId = salarhead.salaryHeadId;
    this.insertSalaryhead.salaryHeadName = salarhead.salaryHeadName;
    this.insertSalaryhead.addDeductIndic = salarhead.addDeductIndic;
    this.insertSalaryhead.statuatoryCode = salarhead.statuatoryCode == undefined ? '' : salarhead.statuatoryCode;

  }

  deleteSalaryHead(headid: any) {
    if (headid != null && headid != undefined) {
      this.empsalaryservice.deleteSalaryHead(headid).subscribe((date) => {
        this.alertmessages("Successfully deleted ", "success");
        this.getSalaryDetail();
      }, (error) => {
        this.alertmessages("Salary Head is used in Salary Detail Master", "danger");
      });
    }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

}
