<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
            <div class="alertcompExcelUpload"></div>
                <div class="card-body px-0">
                    <div class="page-leftheader my-3">
                    <div class="page-title">PF Download</div>
                </div>
            </div>


            <div class="row">
                 <div class="col-xl-12 col-md-12 col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="x_content row p-4">
                                <div class="col-md-4 col-sm-6 col-xs-6">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> Month </label>
                                        <div>
                                            <select id="month" class="form-control" [(ngModel)]="selectedMonth" required="required"
                                                #selectMonth = "ngModel" [ngClass]="(!selectMonth.valid && selectMonth.touched) ? 'is-invalid' : ''" >
                                                <option value="" >Select Month</option>
                                                <option *ngFor="let month of months" [ngValue]="month">{{ month.name }}</option>
                                            </select>
                                        </div>
                                        <span class="help-text text-danger" *ngIf="(!selectMonth.valid && selectMonth.touched)">Please Select Month</span>    
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-6 col-xs-6">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> Year </label>
                                        <div>
                                            <select id="year" class="form-control" [(ngModel)]="year" required="required"  #selectYear = "ngModel"
                                                [ngClass]="(!selectYear.valid && selectYear.touched) ? 'is-invalid' : ''" >
                                                <option value="" selected>Select Year</option>
                                                 <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                                            </select>
                                        </div>
                                        <span class="help-text text-danger" *ngIf="(!selectYear.valid && selectYear.touched)">Please Select Year</span>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-6 col-xs-6">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> File Type</label>
                                        <!-- <div>
                                        </div>      -->
                        
                                        <div>
                                            <select id="filetypes" class="form-control" required="required"  [(ngModel)]="selectedFileType">
                                                <option value="" disabled selected>Select a File Type</option>
                                                 <option *ngFor="let file of filetypes" [value]="file">{{ file }}</option>
                                             </select>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-6 col-xs-6 mb-4">
                                    <label class="form-label w-100 mb-1">Select a Directory </label>
                                    <div class="form-group m-0">
                                        <!-- <div>
                                            
                                                 <input type="text" id="directoryName" class="w-100 form-control" name="myDirectoryName"
                                                 style="box-sizing: border-box;" required="required" [value]="selectedDirectoryName"
                                                 readonly />

                                                  <button type="button" id="navigatetarget" class="w-50 btn btn-primary "
                                            style="margin-right: 1vw"  (click)="handleDirectoryInput($event)">
                                            <i class="fa fa-folder-open"></i>
                                        </button> 

                                    </div> -->

                                    <div>
                                        <input type="text" id="directoryName" class="w-100 form-control" name="myDirectoryName"
                                         style="box-sizing: border-box;" required="required" [value]="selectedDirectoryPath"
                                         readonly />

                                     <!-- Button to trigger directory selection -->
                                         <button type="button" id="navigatetarget" class="w-50 btn btn-primary"
                                            style="margin-right: 1vw" (click)="openDirectory()">
                                            <i class="fa fa-folder-open"></i>
                                        </button>

                                             <!-- Hidden file input for directory selection -->
                                                 <input type="file" id="directoryInput" style="display: none;" webkitdirectory
                                                    (change)="handleDirectoryChange($event)" />
                                    </div>


                                </div>

                             </div>
                             <div>

                                <div class="col-md-3 col-sm-3 col-xs-3 mb-4" style="margin-left:auto">
                                    <div class="form-group m-0">
                                        <!-- <button type="button" id="navigatetarget" class="w-50 btn btn-primary "
                                            style="margin-right: 1vw" (click)="uploadFile()">
                                            Upload File
                                        </button> -->
                                        <button type="button" class="btn btn-success ms-auto" (click)="download()">
                                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                                        </button>
                                        <!-- <button type="button" class="btn btn-danger w-45" (click)="download()">
                                            Download
                                        </button> -->
                                    </div>
                                </div>
                                
</div>

                             </div>
                        </div>
                     
                    </div>   
                </div>
           
           
            </div>
            

                                    
  
            
           
        </div>
    </div>


    