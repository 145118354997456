
export class DocumentInfo{



docGroup: String;

docType: String;

mandatory: String;
description: String;

sortOrder:number;
validityPeriod: String;

docId:number;



}

export class DocumentAuthority {

    selectedJobLevel: any = 'GTE';
    jobRole: number;
    selectedDepartment: any = 'DEP';
    otherDepartment: any;
}