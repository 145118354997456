import { Component, OnInit } from '@angular/core';
import { EmployeeAttendenceProxyService } from 'src/app/services/employee-attendence-proxy.service';

@Component({
  selector: 'app-generate-salary',
  templateUrl: './generate-salary.component.html',
  styleUrls: ['./generate-salary.component.css']
})
export class GenerateSalaryComponent implements OnInit {

  constructor(private employeeService: EmployeeAttendenceProxyService) { }

  addOrUpdate: any;
  branchInfo: any;
  activeEmployeeList: any;

  ngOnInit(): void {
    this.getAllBranches();
    this.getAllActiveEmployee();
  }

  getAllBranches() {
    console.log("Called all branches >> >> >>")
    this.employeeService.getAllBranchs().subscribe(
      data => {
        console.log("check this")
        this.branchInfo = data.branches;
      },
      error => {
      }
    );
  }

  getAllActiveEmployee() {
    console.log("Called all active employee >> >> >>")
    this.employeeService.getAllActiveEmployeeList().subscribe(
      data => {
        this.activeEmployeeList = data.employeeMasterData;
        console.log("activeEmployeeList ", this.activeEmployeeList);
      },
      error => {
      }
    );
  }


  generateSalary() {
    console.log("generate Salary function ");
  }

  fetchSalary() {
    console.log("fetch Salary function ");
  }

  voidSalary() {
    console.log("void Salary function ");
  }

  generateSalaryDownload() {
    console.log("download Salary function ");
  }

}
