<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">
        </div>
        <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Annual Leave Encashment Report</div>
            </div>

        </div>

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">

                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left"
                            (keyup.enter)="limitpara();searchLeaveEncashReport()">
                        
                            <div class="x_content row px-4">

                                <!-- report from -->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Report From</label>
                                        <div>
                                            <input class="form-control w-100" type="date" name="fromDate"
                                                autocomplete="off" autofocus="true" [disabled]="false"
                                                [(ngModel)]="fromDate" (ngModelChange)="checkFromAndToDate()" required>
                                        </div>
                                    </div>
                                </div>
                                <!-- report two  -->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Report To</label>
                                        <div>
                                            <input class="form-control w-100" type="date" name="toDate"
                                                autocomplete="off" autofocus="true" [disabled]="false"
                                                [(ngModel)]="toDate" (ngModelChange)="checkFromAndToDate()" required>
                                            <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                ⓘ End date should be greater than start date
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6 col-xs-6 mb-4">
                                    <label class="form-label w-100 mb-1">Employee
                                        Name / ID </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <input type="search" class=" w-100 form-control" name="employeename"
                                                id="employeename" placeholder="Enter Employee Name / ID"
                                                [(ngModel)]="employeename" autocomplete="off"
                                                (input)="getEmployeeByNameOrId()" list="employeeMasterLucene">
                                            <datalist id="employeeMasterLucene">
                                                <option data-value={{emp.name}}
                                                    *ngFor="let emp of employeeMasterLucene">
                                                    {{emp.employeeid}}
                                                </option>
                                            </datalist>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <!-- <form class="form-horizontal card-body row label-floating px-4" id="fcForm"
                                (keyup.enter)="limitpara();searchLeaveEncashReport()"> -->

                            <!-- search bar 5 -->
                            <!-- <div [ngStyle]="{'display': showSearchBar ? 'inline-block' : 'none'}" -->
                            <!-- <div class="col-md-4 col-sm-6 col-xs-6 mb-4">
                                    <label class="form-label w-100 mb-1">Employee
                                        Name / ID </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <input type="search" class="form-control" name="employeename"
                                                id="employeename" placeholder="Enter Employee Name / ID"
                                                [(ngModel)]="employeename" autocomplete="off"
                                                (input)="getEmployeeByNameOrId()" list="employeeMasterLucene">
                                            <datalist id="employeeMasterLucene">
                                                <option data-value={{emp.name}}
                                                    *ngFor="let emp of employeeMasterLucene">
                                                    {{emp.employeeid}}
                                                </option>
                                            </datalist>
                                        </div>
                                    </div>
                                </div> -->

                            <div class="col-md-3 col-sm-3 col-xs-3 mb-4" style="margin-left:auto">
                                <label class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                                <div class="form-group m-0">
                                    <button type="button" id="navigatetarget" class="btn btn-primary w-45"
                                        style="margin-right: 1vw" (click)="limitpara();searchLeaveEncashReport();">
                                        Search
                                    </button>
                                    <button type="button" class="btn btn-danger w-45" (click)="clear()">
                                        Clear
                                    </button>
                                </div>
                            </div>

                            <!-- </form> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>










        <!-- <form class="form-horizontal" id="fcForm" (keyup.enter)="limitpara(); searchLeaveEncashReport()"> -->
        <!-- <div class="form-group row">
                                <div class="col-md-12 px-0 ">
                                    <label for="inputName" class="w-100  fontSize-15 form-label mb-2 p-0 ">Employee Name
                                        / ID</label>
                                    <input type="search" class="textfieldstyle" name="employeename" id="employeename"
                                        placeholder="Enter Employee Name / ID" [(ngModel)]="query" autocomplete="off"
                                        (input)="getEmployeeByNameOrId()" list="employeeMasterLucene">
                                    <datalist id="employeeMasterLucene">
                                        <option data-value={{emp.name}} *ngFor="let emp of employeeMasterLucene">
                                            {{emp.employeeid}}
                                        </option>
                                    </datalist>
                                </div>
                                    <div class="row col-md-12 col-sm-12 col-xs-12 borderTop"> 
                                         <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group label-floating">
                                                <label class="w-100  fontSize-15 form-label mb-2 p-0 ">From</label>
                                                <div class="w-100">
                     
                                                    <input class="form-control" type="date" name="fromDate" [value]="fromDate"
                                                                    autofocus="true" [disabled]="false" [(ngModel)]="fromDate"
                                                                    (ngModelChange)="checkFromAndToDate()" required>
                                                             </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-xs-6"> 
                                             <div class="form-group label-floating">
                                                <label class="w-100  fontSize-15 form-label mb-2 p-0 ">To<span
                                                        style="color: rgb(248, 111, 111);">
                                                        </span></label>
                                                <div class="w-100">
                                                    <input class="form-control" type="date" name="toDate" [value]="toDate"
                                                    autofocus="true" [disabled]="false"  [(ngModel)]="toDate"
                                                    (ngModelChange)="checkFromAndToDate()" required>
                                                <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                    ⓘ End date should be greater than start date
                                                </p>          
                                                </div>
                                            </div>
                                         </div>
                                    </div>
                                
                            </div> -->
        <!-- <button type="button" id="navigatetarget"
                                class="btn btn-primary w-100  w-md-20 mt-3 mt-md-0 p-2 p-md-1"
                                (click)="limitpara(); searchLeaveEncashReport(); ">
                                <i class="fa fa-search" aria-hidden="true"></i> Search
                            </button> -->
        <!-- </form> -->
        <!-- </div>
                </div>

            </div>
        </div> -->



        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title "> Leave Encashment Details </h4>
                        <button type="button" class="btn btn-primary ms-auto" (click)="employeeMasterCSVDownload()">
                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                        </button>
                    </div>

                    <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>
                    <div class="card-body">
                        <div class="col-xs-12  mobile view">

                            <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                                (scrolled)="onScroll()" id="scrolllength">

                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-table">
                                    <thead class="tablerow">
                                        <tr class=" stickyatTop rowcolors">

                                            <th class="border-bottom-0 w-8 fs-7 fixedcolumn">Employee Unique ID</th>
                                            <th class="border-bottom-0 w-8 fs-7 fixedcolumn  secondcolumn">Employee Name
                                            </th>
                                            <th class="border-bottom-0 w-10 fs-7">Leave Type</th>
                                            <th class="border-bottom-0 w-10 fs-7">No of Days</th>
                                            <th class="border-bottom-0 w-10 fs-7">Applied On</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let j=index; let empInfo of leaveEncashmentList ">



                                            <td class="fixedcolumn backgroundFW" data-th="Employee Id">
                                                {{empInfo.empId}}</td>
                                            <td class="fixedcolumn secondcolumn  backgroundFW backgroundSW"
                                                data-th="Employee Name"> {{empInfo.empname}}

                                            </td>
                                            <td data-th="Leave Type">
                                                {{empInfo.leaveType}}</td>
                                            <td data-th="No of Days">
                                                {{empInfo.nod}}</td>
                                            <td data-th="Applied On">
                                                {{empInfo.appliedDate | date: 'MM/dd/yyyy' }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>