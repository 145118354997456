import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CommonService } from "./common.service";
import { ExtraTimeOut } from "../models/extra-time-out";
import { Observable, of } from 'rxjs';
import { ExtraTimeOutWorkflow } from "../models/extraTimeOutWorkflow";

@Injectable({
    providedIn: 'root'
  })
export class Extratimeoutservices {

    protected basePath = '/api';
    public defaultHeaders: Headers = new Headers();
    public url!: string;
    public colorList = ['#33c5ff', '#f7284a', '#01c353', '#fe7700', '#c3c001', '#33e0ff', '#6574cd', '#ef4eb8', '#ecb403', '#17a2b8'];
    public empLeaves: string[] = []
    constructor(private httpclient: HttpClient, private commonService: CommonService) { }
  

    public saveExtraTimeOut(extraTimeOut: ExtraTimeOut): Observable<any> {
        this.url = '/v1/savingExtraTimeOut';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        console.log("save Extratiomeout on submit ")
        return this.commonService.postWithHttpInfo(extraTimeOut, queryParameters, path);
      }

      public getBalanceExtraTimeOut(uniqueEmployeeId: any, date:any): Observable<any> {
        this.url = '/v1/getBalanceExtraTimeOut';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        if (uniqueEmployeeId !== null || uniqueEmployeeId !== undefined) {
          queryParameters = queryParameters.set('uniqueEmployeeId', uniqueEmployeeId);
        }
        queryParameters = queryParameters.set('date', date);
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
      }

      public getExtraTimeOutApplied(uniqueEmployeeId: any): Observable<any> {
        this.url = '/v1/getExtraTimeOutApplied';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        if (uniqueEmployeeId !== null || uniqueEmployeeId !== undefined) {
          queryParameters = queryParameters.set('uniqueEmployeeId', uniqueEmployeeId);
        }
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
      }

      deleteExtraTimeOutApplied(employeeExtraTimeOutApplied: any): Observable<any> {
        this.url = '/v1/deleteExtraTimeOutApplied';
        const path = this.basePath + this.url;
        const options = {
          body: employeeExtraTimeOutApplied
        };
        return this.httpclient.request('delete', path, options);
      }
      public getExtraTimeOutlist(): Observable<any> {
        this.url = '/v1/getExtraTimeOutList';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        // if (uniqueEmployeeId !== null || uniqueEmployeeId !== undefined) {
        //   queryParameters = queryParameters.set('uniqueEmployeeId', uniqueEmployeeId);
        // }
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
      }

      public extraTimeOutForApproval(taskDetails: ExtraTimeOutWorkflow[], approve_reject: boolean): Observable<any> {
        console.log("both for approve and reject  ", taskDetails);
        this.url = '/v1/approveExtraTimeOut';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('approve_reject', approve_reject);
        return this.commonService.postWithHttpInfo(taskDetails, queryParameters, path);
      }

      public getAllExtraTimeOut(): Observable<any> {
        this.url = '/v1/getAllExtraTimeOut';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
      }
      getFilteredETOSummary(selecteduniqueeEmpId: any, fromDate: any, toDate: any, status: any, limitPara: any, offsetPara: any): Observable<any> {
       
        this.url = '/v1/getFilteredETOSummary';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        // if (selecteduniqueeEmpId !== null) {
          queryParameters = queryParameters.set('uniqueeEmpId', selecteduniqueeEmpId);
        // }
        queryParameters = queryParameters.set('limitPara', limitPara);
        queryParameters = queryParameters.set('offsetPara', offsetPara);
        queryParameters = queryParameters.set('fromDate', fromDate);
        queryParameters = queryParameters.set('toDate', toDate);
        queryParameters = queryParameters.set('status', status);
    
        console.log("Satus value : ", status)
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
      }
    
      public downloadETOReportCSVFile(extraTimeOut: ExtraTimeOut): Observable<any> {
        this.url = '/v1/downloadExtraTimeOutReport';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        console.log("Extratiomeout Report", extraTimeOut)
        return this.commonService.postWithHttpInfo(extraTimeOut, queryParameters, path ,{ responseType: 'arraybuffer' });
      }
       //delete for overtime
  deleteoverTimeApplied(employeeoverTimeApplied: any): Observable<any> {
    this.url = '/v1/deleteoverTimeApplied';
    const path = this.basePath + this.url;
    const options = {
      body: employeeoverTimeApplied
    };
    return this.httpclient.request('delete', path, options);
  }

//get method for overtime
  public getoverTimeApplied(uniqueEmployeeId: any): Observable<any> {
    this.url = '/v1/getoverTimeApplied';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueEmployeeId', uniqueEmployeeId);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public saveoverTime(extraTimeOut: ExtraTimeOut): Observable<any> {
    this.url = '/v1/savingoverTime';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("save overtime  on submit ")
    return this.commonService.postWithHttpInfo(extraTimeOut, queryParameters, path);
  }

    
}
