export class salaryAdvance{
    interMasterId: number;
    totalAmount : number;
    uniqueemployeeid: number;
	noOfInstallment: number;
	amountDate: Date;
	dateOfLoan: Date;
	installStartDate: Date;;
	installEndDate: Date;
	installStartMonth: any;
	installEndMonth: any;
	installmentComp = [];
	userid: any;
	status: String;
	empInstMasterId: number;;
}