import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-approve-employee-overtime',
  templateUrl: './approve-employee-overtime.component.html',
  styleUrls: ['./approve-employee-overtime.component.css']
})
export class ApproveEmployeeOvertimeComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

}
