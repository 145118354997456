<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">

        </div>
        <!--Page header-->
        <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Over time Approval</div>
            </div>
        </div>


        <div class="row">
            <div class="col-x1-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <form id="fcform" class="form-horizantal form-label-left">
                           <form class="form-horizantal card-body row label-floating px-4" id="fcform">

                            <div class="col-md-6 col-sm-12 col-xs-12 mb-4">
                                <label class="p-0 form-label col-x1-6 col-md-6 col-lg-6">Employee Name / Id
                                </label>
                                <div class="form-group m-0">



                                    
                                </div>





                            </div>













                           </form>







                        </form>









                    </div>








                </div>









            </div>




        </div>





    </div>
</div>