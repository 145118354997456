import { Injectable } from '@angular/core';
import { Request, XHRBackend, RequestOptions, Response, Http, RequestOptionsArgs, Headers } from '@angular/http';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { SessionService } from './../services/index';

declare var $: any;

@Injectable()
export class OutgoingInterceptor implements HttpInterceptor {

    reqInProgress: any;

    constructor(backend: XHRBackend, defaultOptions: RequestOptions, private router: Router, private sessionService: SessionService) {
        this.reqInProgress = 0;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):

        Observable<HttpEvent<any>> {

        let ok: string;
        const started = Date.now();

        let authToken;

        let notUseToken = false;

        if (req.url.indexOf('/login') !== -1 || req.url.indexOf('/signUp') !== -1 || req.url.indexOf('/forgotPassword') !== -1 || req.url.indexOf('/customerSearch/unauthorizedUser') !== -1) {
            notUseToken = true;
        }


        if (notUseToken === true) {
            /*for oauth login api call*/
            authToken = 'Bearer ' + '';

        } else if (this.sessionService.getAuthToken()) {
            authToken = 'Bearer ' + this.sessionService.getAuthToken();

        }

        let reqHead = req.headers;
        reqHead = reqHead.set('Authorization', authToken);

        
        /*to set contet-type only for oauth login api call*/
        /*
        if (notUseToken !== true &&  req.url !== '/api/v1/employee/document' && ) {
            reqHead = reqHead.set('Content-Type', 'application/json');
        }

        */

        if (notUseToken !== true &&  req.url !== '/api/v1/employer/uploadRulesPolicy' && req.url !==  '/api/v1/employee/document' && req.url !=='/api/v1/uploadProfilePhoto' && req.url !=='/api/v1/muliDocument' && req.url !=='/api/v1/fileupload' && req.url !=='/api/v1/saveprojectdoc' && req.url !=='/api/v1/employeeDataUpload' && req.url !== '/api/v1/addExpense' && req.url !== '/api/v1/addPolicySubmission' && req.url !== '/api/v1/salaryManualFedUpload') {
            reqHead = reqHead.set('Content-Type', 'application/json');
            console.log("The reqest url is ", req.url )
            console.log("The Request Head is", reqHead)
        } 

        const authReq = req.clone({
            headers: reqHead
        });

        this.reqInProgress++;

        // loader shouldn't be visible 
        console.log(req.url , "request url" , req.url.indexOf('/api/v1/employeesearch'))
        if(req.url !== '/api/v1/employeesearch' && req.url!=='/api/v1/notification' && req.url!=='/api/v1/readnotification' ){
            document.getElementById('loader').style.display = 'block';
        }
        
        // document.getElementById('loader').style.display = 'block';
        
        return next.handle(authReq)
            .pipe(
                tap(
                    // Succeeds when there is a response; ignore other events
                    event => {
                        ok = event instanceof HttpResponse ? 'succeeded' : '';
                        console.log('-------------ok result ---------------- ' + ok);

                        if (event instanceof HttpResponse) {
                            let h: HttpHeaders;
                            h = event.headers;

                            if (h.get('authorization')) {

                                /*setting user's auth token with new Authorization header*/
                                this.sessionService.setAuthToken(h.get('authorization'));

                                // console.log ('------------------authorization -----------------------' + sessionStorage.getItem('authToken'));
                            }

                            // this.sessionService.setTokenUpdatedTime(req.url);

                            return event;
                        }
                    },
                    error => {
                        ok = 'failed';
                        console.log('-------------ok result ---------------- ' + ok);
                        if ((error.status === 401 || error.status === 403)) {

                            $('.modal').each(function (index, value) {
                                if (value.style.display === 'block') {
                                    $('#' + value.id).modal('toggle');
                                }
                            });
                            $('.modal-backdrop').hide();
                            this.router.navigate(['/login']);

                            return throwError(error);
                        }

                    }
                ),
                // Log when response observable either completes or errors
                finalize(() => {
                    const elapsed = Date.now() - started;
                    const msg = `${req.method} '${req.urlWithParams}'
                     ${ok} in ${elapsed} ms.`;

                    this.reqInProgress--;

                    if (this.reqInProgress < 1) {
                        document.getElementById('loader').style.display = 'none';
                    }

                    // console.log( '------------------msg -----------------------' + msg );
                    // this.messenger.add(msg);
                })
            );
    }

}
