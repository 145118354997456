import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TaskDetailsForExtraTimeOut } from '../models/task-deatais-for-extraTimeOut';

@Pipe({
  name: 'approveExtraTimeOut'
})
@Injectable({
  providedIn:'root'
})
export class ApproveExtraTimeOutPipe implements PipeTransform {

  transform(approvalLeave:TaskDetailsForExtraTimeOut[], searchleave:string): TaskDetailsForExtraTimeOut[] {
    if(!approvalLeave || !searchleave){
      // console.log('Non search', approvalLeave);
      return approvalLeave;
    }

    // console.log('search ', approvalLeave,'term ',searchleave);
    const testLeave = approvalLeave.filter(task=>task.employeeName.toLowerCase().indexOf(searchleave.toLowerCase())!=-1)
    // console.log("Pipe Leave ",testLeave);
    
    return approvalLeave.filter(task=>task.employeeName.toLowerCase().indexOf(searchleave.toLowerCase())!=-1);
  }
  }


