<div class="app-content main-content">
    <div class="side-app main-container">
      <div class="alertcomp"></div>
      <div class="card-body px-0">
        <div class="page-leftheader my-3">
          <div class="page-title">Salary Advance</div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-md-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <form id="salaryadvanceform" class="form-horizontal form-label-left row" #salaryadvanceform="ngForm">
                <div class="row col-md-12 col-sm-12 col-xs-12">
 
                  <div class="col-md-3 col-sm-4 col-xs-4">
                    <div class="form-group label-floating">
                      <label class="form-label">Amount</label>
                      <div>
                        <input type="text" class="form-control col-md-12 col-xs-12" autocomplete="off"
                          placeholder="Enter amount" name="enteredamount1"  [(ngModel)]="totalAmount" required #enteredamount="ngModel"
                          [ngClass]="(!enteredamount.valid && enteredamount.touched) || (!enteredamount.valid && submitted) ? 'is-invalid' : ''" />
                      </div>
                      <span class="help-text text-danger" *ngIf="(!enteredamount.valid && enteredamount.touched) || (!enteredamount.valid && submitted)">Please Enter
                        Amount</span>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-4 col-xs-4">
                    <div class="form-group label-floating">
                      <label class="form-label">Number of Installment</label>
                      <div>
                        <input type="text" class="form-control col-md-12 col-xs-12" autocomplete="off"
                          placeholder="Enter Number of Installment" name="enterednoOfInstall"  [(ngModel)]="noOfInstallment" required #enterednoOfInstall="ngModel"
                          [ngClass]="(!enterednoOfInstall.valid && enterednoOfInstall.touched) || (!enterednoOfInstall.valid && submitted) ? 'is-invalid' : ''" />
                      </div>
                      <span class="help-text text-danger" *ngIf="(!enterednoOfInstall.valid && enterednoOfInstall.touched) || (!enterednoOfInstall.valid && submitted)">Please Enter
                        Number of Installment</span>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-xs-6">
                    <div class="form-group label-floating">
                      <label class="form-label"> Payment required on </label>
                      <div>
                        <input type="month" class="form-control" name="date_of_loan" [(ngModel)]="date_of_loan"
                          required #dateofloan="ngModel" (change)="onDateChange()"
                          [ngClass]="(!dateofloan.valid && dateofloan.touched) ? 'is-invalid' : ''" />
                      </div>
                      <span class="help-text text-danger" *ngIf="(!dateofloan.valid && dateofloan.touched)">Please Enter
                        Date of Expense </span>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 col-xs-6">
                    <div class="form-group label-floating">
                      <label class="form-label"> Installment Deduction From </label>
                      <div>
                        <input type="month" class="form-control" name="Ins_StartDate" [(ngModel)]="Ins_StartDate"
                          required #InsStartDate="ngModel"
                          [ngClass]="(!InsStartDate.valid && InsStartDate.touched) ? 'is-invalid' : ''" />
                      </div>
                      <!-- <span class="help-text text-danger" *ngIf="(!dateofloan.valid && dateofloan.touched)">Please Enter
                        Date of Expense </span> -->
                    </div>
                  </div>



                  <div class="actionBar">
                    <!-- Left side button -->
                    <button *ngIf="!isEdit" class="btn btn-primary" type="button" style="float: left;" (click)="calcInstallment()"
                     data-bs-toggle="modal" data-bs-target="#calInstallmentModel">
                      Installment Calculation
                    </button>

                    <button *ngIf="isEdit" class="btn btn-primary" type="button" style="float: left;" (click)="calcInstallment()"
                    data-bs-toggle="modal" data-bs-target="#calInstallmentModel">
                     Installment Calculation
                   </button>
                  
                    <!-- Right side buttons -->
                    <div style="float: right;">
                      <button *ngIf="!isEdit" class="btn btn-success" type="button" (click)="submit()">
                        Save
                      </button>
                      <button *ngIf="isEdit" class="btn btn-success" type="button" (click)="submit()">
                        Update
                      </button>
                      <button class="btn btn-danger ms-2" type="button" (click)="clear()">
                        Reset
                      </button>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="card col-md-12 col-sm-12 col-xs-12">
        <div class="card-header border-bottom-0">
          <h3 class="card-title">List of Salary Advance</h3>
        </div>
        <div class="card-body card1S p-0">
          <div class="table-responsive">
            <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
              <thead class="tablerow">
                <tr class="rowcolors stickyatTop">
                  <th class="border-bottom-0 fs-7">Advance ID</th>
                  
                  <th class="border-bottom-0 fs-7">Advance Date</th>
                 
                  <th class="border-bottom-0 fs-7">Amount</th>
                 
                  <th class="border-bottom-0 fs-7">Status</th>
                  <th class="border-bottom-0 fs-7">Action</th>
                </tr>
              </thead>
              <tbody style="max-height: 50vh; overflow: scroll; padding-bottom: 10vh;">
                <tr *ngFor="let sal of salaryAdvanceList; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ sal.installStartDate }}</td>
                  <td>{{ sal.totalAmount }}</td>
                   
                  <td>
                    <span *ngIf="sal.status === 'PENDING'"
                        class="badge badge-warning">{{sal.status}}</span>
                    <span *ngIf="sal.status === 'APPROVED'"
                        class="badge badge-success">{{sal.status}}</span>
                    <span *ngIf="sal.status === 'REJECTED'"
                        class="badge badge-danger">{{sal.status}}</span>
                </td>
                  <td>
                    <button class="btn btn-primary btn-icon btn-sm me-2"
                    data-method="edit" title="Edit"
                    (click)="editSalaryAdvance(sal)">
                    <i class="fa fa-edit" data-bs-toggle="tooltip"
                        data-original-title="edit"></i>
                    </button>
  
                    <!-- <button class="btn btn-primary btn-icon btn-sm me-2"
                       style="width: 32px;">
                      <i class="fa fa-eye" ></i>
                    </button> -->
                    <button class="btn btn-danger btn-icon btn-sm me-2" (click)="delete(sal)">
                      <i class="fa fa-trash" ></i>
                    </button>
                  </td>
                </tr>
                <br><br><br>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      </div>

      <div class="modal  fade" id="calInstallmentModel" tabindex="-1" aria-labelledby="calInstallmentModel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="calInstallmentModellLabel" style="font-size: 20px;">Installment Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                </div>
                <div class="modal-body">
                    <div class=" container-fluid mb-6">

                        <!-- <div>Salary Detail Additional Component</div> -->

                        <!-- <h5 class="card-title mb-3 "> Salary Detail Additional Component </h5> -->

                        <div class="mobile">
                            <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                id="hr-table1">
                                <!-- <table class="table  table-vcenter text-nowrap table-bordered border-bottom"> -->
                                <thead class="tablerow">
                                    <tr class="rowcolors">
                                        <th class="border-bottom-0 w-5 fs-6 ">Date</th>
                                        <th class="border-bottom-0 w-5 fs-6 ">Installment Amount</th>
                                        <th class="border-bottom-0 w-5 fs-6 ps-3">Paid Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of model.installmentComp ; let i = index">
                                        <td data-th="Date">{{item?.monthName }}</td>
                                        <td data-th="Installment Amount">
                                          <input type="number" min="0" class="form-control" (input)="changeAmount($event,item , i)" 
                                          [value]="item?.instAmount " placeholder="Enter amount"></td>
                                        <td data-th="Paid Amount">
                                            <!-- <input type="number" min="0" class="form-control "
                                                (input)="readamount($event,item , true)" placeholder="Enter amount"
                                                data-name="{{item?.salaryDetailName}}"
                                                [value]="item?.salaryComponentAmount === -1 ? 0.0 : item?.salaryComponentAmount"> -->
                                                <input type="number" min="0" class="form-control " [value]="item?.paidAmount "
                                                placeholder="Enter amount" disabled>
                                               
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

 

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Reset</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" >Confirm</button>
                </div>
            </div>
        </div>
    </div>
 
   
  </div>
