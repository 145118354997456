import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { EmployeeLeaveService } from 'src/app/services/employee-leave.service';
import { EmpsalaryslipService } from 'src/app/services/empsalaryslip.service';

@Component({
  selector: 'app-salary-fed',
  templateUrl: './salary-fed.component.html',
  styleUrls: ['./salary-fed.component.css']
})
export class SalaryFedComponent implements OnInit {

  excelDoc: any = {};
  fileToUpload: File | null = null;
  submitted: boolean;
  public files: any[] = [];
  fileslength: boolean;
  multipleFilePath = [];
  uploadedDocDetails = [];
  loading: boolean = false; 
  requestDepartmentObj: any[] = [];
  uploadError: boolean = false;
  excelErrors: string[] = [];

  years: string[] = [];
  directoryPath: string = '';
  year: any;
  selecteduniqueeName: any;
  selecteduniqueeEmpId:any;
  employeeList: any[] = [];
  query: any;
  queryName: any;
  employeeMasterLucene: EmployeeMaster[];
  isFileValid: boolean = true;
  

  months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  
  selectedMonth: { name: string, value: number } | undefined;

  @ViewChild('directoryPicker', { static: false }) directoryPicker!: ElementRef<HTMLInputElement>;

  constructor(private empsalaryservice: EmpsalaryslipService,private empLeaveService: EmployeeLeaveService,private _snackBar: MatSnackBar) { }

  ngOnInit(): void {

    const currentMonthValue = new Date().getMonth() + 1; // getMonth() returns 0 for January, so +1
    this.selectedMonth = this.months.find(month => month.value === currentMonthValue);

    const currentYear = new Date().getFullYear();
    // this.years.push(''); // Add empty option
    for (let year = currentYear; year >= 2018; year--) {
      this.years.push(year.toString());
    }
    this.year = currentYear.toString();
    this.getEmployeeList();
  }

  uploadFile() {

    console.log("File in Upload File", this.fileToUpload)

    this.submitted = true;
    if (this.fileToUpload?.size == 0) {
      return;
    }

    if (!this.fileToUpload) {
      this.alertmessages("Please select a file before uploading", "danger");
      return;
  }

    const typeList = ['xls'];
    console.log("Step one check : fileArray", this.fileToUpload)
    if (typeList.includes(this.fileToUpload.name.split(".").pop())) {
      this.isFileValid = true;
      this.loading = !this.loading;
      this.uploadedDocDetails = [];
      console.log("The Name of Uploaded Files : I If loop", this.fileToUpload.name);

      this.empsalaryservice.uploadEmpExcelDocument(this.fileToUpload).subscribe(
        data => {
          console.log("The Error is ", data);
          this.excelErrors = data.excellmessages;
          if (this.excelErrors && this.excelErrors.length > 0) {
            this.uploadError = true;
            console.log("The Error is1 ", this.excelErrors);
            this.alertmessagesWithLink("There Are errors in excel.", "danger");
          } else {
                  // The array is empty or undefined
            console.log('No errors found.');
            this.uploadError = false;
            // this.alertmessagesWithLink("File Uploaded Successfully.", "success");
            this.alertmessages("File Uploaded Successfully.", "success");
          }
        },
        error => {
          console.log("The Error is ", error);
          // this.alertmessages("Failed to Save", "danger");
        });

    } else {
      this.isFileValid = false;
    }
  }

  handleFileInput(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileToUpload = file;
    }
  }

  openDirectoryPicker() {
    const directoryPicker = document.getElementById('directoryPicker') as HTMLInputElement;
    if (directoryPicker) {
        directoryPicker.click();
    }
}

onDirectorySelected(event: any) {
  const files = event.target.files;
  if (files.length > 0) {
      const directoryPath = files[0].webkitRelativePath.split('/')[0];
      this.directoryPath = directoryPath;
      console.log('Directory selected:', this.directoryPath);  // Debugging output
  } else {
      console.log('No directory selected.');  // Debugging output
  }
}

  filename:"salaryMAnualFed";

  downloadFile() {

    console.log('year',this.year);
    console.log('month',this.selectedMonth);
    console.log('month',this.query);
    console.log('month',this.selecteduniqueeEmpId);

    if(this.query!=undefined || this.query != null){
    const [name, id] = this.query.split(' / ');

    this.selecteduniqueeName =name;
    this.selecteduniqueeEmpId =id;
    }
    if(!this.year){
      console.log('year',this.year);
      return;
    }
    // if(this.selectedMonth.value.i)
    if (!this.selectedMonth || !this.selectedMonth.name || !this.selectedMonth.value) {
      console.log('month',this.selectedMonth);
      return;
    }
    if(this.selecteduniqueeEmpId == 'Select Employee'){

      this.selecteduniqueeEmpId == '';

    }

    this.empsalaryservice.downloadFile(this.year,this.selectedMonth.value.toString(),this.selectedMonth.name.toString(),this.selecteduniqueeEmpId).subscribe(
      (data) => {
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "salaryManualUpload"+this.selectedMonth.name.toString()+this.year+".xls";
      anchor.href = fileURL;
      anchor.click();
      
      },
      (error) => {
        console.log("Error Occured ", error);
      }
    );
  }

  getEmployeeList() {
    this.empsalaryservice.getEmployeeList().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.employeeList = data.getPeople;
        console.log("List of all the Employee available :", data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  selectDirectory() {
    const input = document.createElement('input');
    input.type = 'file';
    input.webkitdirectory = true;

    input.addEventListener('change', (event: any) => {
      this.directoryPath = event.target.files[0].path; // Capture directory path
    });
    console.log(this.directoryPath);
    input.click();
  }

  onEmployeeSelect(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const selectedOption = inputElement.list?.querySelector(`[value="${inputElement.value}"]`);
    
    if (selectedOption) {
        this.selecteduniqueeEmpId = selectedOption.getAttribute('data-value');
    }
  }

  getEmployeeByNameOrId() {
    
    const [name, id] = this.query.split(' / ');
    this.queryName = name;
    console.log("Len ", this.query);
    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = []
    }
    if (this.queryName.length >= 3) {

      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data);
          this.employeeMasterLucene = data.employeeMasterData

          // if(this.employeeMaster.length > 0){
          // 	const autoCompleteId = document.getElementById("employeeMaster");
          // 	autoCompleteId.classList.remove("auto-complete-active");
          // }
        },
        error => {
          console.log("Error ", error);

        }
      )
    }
  }

  clear() {
    this.requestDepartmentObj = [];
    let checkallele = document.querySelectorAll('.deCheckall');

    checkallele.forEach(item => {
      (<HTMLInputElement>item).checked = false
    })

    // checkallele
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  alertmessagesWithLink(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    if(this.uploadError){
      alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
      <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} 
      <button id="viewErrorsBtn" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#excelErrors">View Errors</button>
      </div>`
    }else{
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    }

    let alertcomp = document.querySelector('.alertcompExcelUpload');
    alertcomp.append(alertDiv);
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // this.router.navigate(['/employeemaster']);
      });


    }, 5000);
  }
  
}
