import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { AlertService, ConstantService, SessionService } from 'src/app/services';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { EmployeeMasterservice } from 'src/app/services/employeemaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeLeaveEncashReportService } from '../services/emp-leave-encash-report.service';
import { DocumentInfoService } from '../services/documentinfo.service';
import { Approvalinboxservice } from '../services/approvalinbox.service';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { LeaveEncashmentInfo } from '../models/employee-leave-encash-info.model';
import { EmployeeLeaveEncashService } from '../services/employee.leave.encash.service';

declare var $: any;
@Component({
  selector: 'app-employee-leave-encash-report',
  templateUrl: './emp-leave-encash-report.component.html',
  styleUrls: ['./emp-leave-encash-report.component.css']
})
export class EmployeeLeaveEncashReportComponent implements OnInit {
  employeeMasterLucene: EmployeeMaster[];
  startDate: boolean;
  endDate: boolean;
  submitted: boolean;
  fromDate: string;
  toDate: string;

  leaveEncashmentList: LeaveEncashmentInfo[];

  LeaveTypeList: [];
  employeeMaster: EmployeeMaster;

  url: string | ArrayBuffer;

  empMasterInfo1: EmployeeMaster;
  uniqueemployeeid: string;

  employeename: string;

  query: any;


  // datas must load when when we scroll down
  limitPara: any;
  offsetPara: any;
  id: any;
  currentDate: string;
  permissionList: any[] = [];
  showBasedOnPermissionForAddOnComponent: boolean = false;
  showBasedOnPermissionForLeaveType: boolean = false;
  accessToEditAndView: boolean = false;
  allowBlankSearch: boolean = false;
  profileName: any;
  queryName: any;




  constructor(private render: Renderer2, private route: ActivatedRoute, private router: Router,
    private employeeMasterservice: EmployeeMasterservice, private empLeaveEncashReportService: EmployeeLeaveEncashService,
    private docmentInfoService: DocumentInfoService, private empLeaveService: EmployeeLeaveService, private datepipe: DatePipe) {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

  }


  ngOnInit() {
    this.leaveEncashmentList = new Array<LeaveEncashmentInfo>();
    this.employeeMaster = new EmployeeMaster;
    this.empMasterInfo1 = new EmployeeMaster;


    this.limitPara = 17;
    this.offsetPara = 0;


    (<HTMLInputElement>document.getElementById('employeename')).focus();

    const queryParams = this.route.snapshot.queryParams;

    console.log("queryParams : ", queryParams)
    console.log("queryParams type : ", typeof queryParams)

    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');


    this.render.listen(document.getElementById('navigatetarget'), 'click', () => {
      $('html,body').animate({
        scrollTop: $(`#navigatedest`).offset().top - 90
      },
        'slow');
    })
  }

  searchLeaveEncashReport() {
    this.empLeaveEncashReportService.getLeaveEncashRepot(this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datepipe.transform(this.toDate, 'yyyy-MM-dd'), this.employeename, this.limitPara, this.offsetPara).subscribe(
      (data) => {

        this.leaveEncashmentList = data.encashableLeaveDetail;
        //   console.log("Data available :", data);
        //  // this.alertmessages("successfully updated","success");
        //  this.leaveEncashmentList = data.encashableLeaveDetail;

      },
      (error) => {
        console.log(error);
      }
    );

  }

  searchLeaveEncashReport1() {
    console.log("check : ", typeof this.query);

    if (this.allowBlankSearch) {
      var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
      if (!employeename) {
        employeename = null;
      } else {
        employeename = employeename.trim()
      }

      if (this.uniqueemployeeid === undefined) {
        this.uniqueemployeeid = null
      }
      console.log("permisison : ", this.allowBlankSearch)
      console.log("Initial value before checking ")
      console.log("Unique Id : " + this.uniqueemployeeid);
      console.log("Employee Name  : " + employeename);
      if (this.allowBlankSearch) {
        console.log(" ")
        console.log("true  : ", this.allowBlankSearch)
        console.log("Unique Id : " + this.uniqueemployeeid);
        console.log("Employee Name  : " + employeename);

        this.empLeaveEncashReportService.getLeaveEncashRepot(this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datepipe.transform(this.toDate, 'yyyy-MM-dd'), this.uniqueemployeeid, this.limitPara, this.offsetPara).subscribe(

          data => {
            this.leaveEncashmentList = data.encashableLeaveDetail;
            console.log(" data : ", this.leaveEncashmentList);

            // // START onScrollLoadData
            // if (this.limitPara == 0) {
            //   this.employeeMasterList = data.employeeMasterData;
            //   console.log(" data : ", this.employeeMasterList);
            // } else {
            //   this.employeeMasterList = this.employeeMasterList.concat(data.employeeMasterData);
            //   console.log("data concat : ", this.employeeMasterList);
            // }
            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";
          }, error => {
            console.log(error);
            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";
          }
        );
      } else {
        if (this.uniqueemployeeid == null && employeename == null) {

          console.log(" check null or not ")
          this.alertmessages("Please Enter Employee Name or Unique Employee ID ", "danger");

        } else {
          console.log("uid / ename .....  not null  ")

          console.log("Unique Id : " + this.uniqueemployeeid);
          console.log("Employee Name  : " + employeename);

          this.empLeaveEncashReportService.getLeaveEncashRepot(this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datepipe.transform(this.toDate, 'yyyy-MM-dd'), this.uniqueemployeeid, this.limitPara, this.offsetPara).subscribe(
            data => {  //new

              if (this.limitPara == 0) {
                this.leaveEncashmentList = data.encashableLeaveDetail;
                console.log(" data : ", this.leaveEncashmentList);
              } else {
                this.leaveEncashmentList = this.leaveEncashmentList.concat(data.employeeMasterData);
                console.log("data concat : ", this.leaveEncashmentList);
              }

              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";

            }, error => {
              console.log(error);
              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";
            }

          );
        }
      }
    }
    if (this.query?.length <= 2) {
      var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
      if (!employeename) {
        employeename = null;
      } else {
        employeename = employeename.trim()
      }
      if (this.uniqueemployeeid === undefined) {
        this.uniqueemployeeid = null
      }
      if (employeename?.trim().length === 0) {
        employeename = null;
      }
      console.log("permisison : ", this.allowBlankSearch)
      console.log("Initial value before checking ")
      console.log("Unique Id : " + this.uniqueemployeeid);
      console.log("Employee Name  : " + employeename);

      if (this.allowBlankSearch) {
        if (Number(employeename)) {
          console.log("nametosearch  : " + employeename);
          this.uniqueemployeeid = employeename;
          employeename = null
        }
        console.log(" ")
        console.log("true  : ", this.allowBlankSearch)
        console.log("Unique Id : " + this.uniqueemployeeid);
        console.log("Employee Name  : " + employeename);

        this.empLeaveEncashReportService.getLeaveEncashRepot(this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datepipe.transform(this.toDate, 'yyyy-MM-dd'), this.uniqueemployeeid, this.limitPara, this.offsetPara).subscribe(
          data => {
            this.leaveEncashmentList = data.encashableLeaveDetail;
            console.log(" data : ", this.leaveEncashmentList);

            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";

          }, error => {
            console.log(error);
            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";
          }
        );
      } else {
        if (this.uniqueemployeeid == null && employeename == null) {
          console.log(" check null or not ")
          this.alertmessages("Please Enter Employee Name or Employee ID ", "danger");
        } else {
          console.log("uid / ename .....  not null  ")
          console.log("Unique Id : " + this.uniqueemployeeid);
          console.log("Employee Name  : " + employeename);
          if (Number(employeename)) {
            console.log("nametosearch  : " + employeename);

            this.uniqueemployeeid = employeename;
            employeename = null
          }
          this.empLeaveEncashReportService.getLeaveEncashRepot(this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datepipe.transform(this.toDate, 'yyyy-MM-dd'), this.uniqueemployeeid, this.limitPara, this.offsetPara).subscribe(
            data => {

              this.leaveEncashmentList = data.encashableLeaveDetail;
              console.log(" data : ", this.leaveEncashmentList);

              this.uniqueemployeeid = null
              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";
            }, error => {
              console.log(error);
              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";
            }
          );
        }
      }
    }
    if (this.query?.length >= 3) {
      var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
      if (!employeename) {
        employeename = null;
      } else {
        employeename = employeename.trim()
      }
      if (employeename?.trim().length === 0) {
        employeename = null;
      }
      if (this.uniqueemployeeid === undefined) {
        this.uniqueemployeeid = null
      }
      console.log("permisison : ", this.allowBlankSearch)
      console.log("Initial value before checking ")
      console.log("Unique Id : " + this.uniqueemployeeid);
      console.log("Employee Name  : " + employeename);

      if (this.allowBlankSearch) {
        console.log(" ")
        console.log("true  : ", this.allowBlankSearch)
        console.log("Unique Id : " + this.uniqueemployeeid);
        console.log("Employee Name  : " + employeename);
        this.empLeaveEncashReportService.getLeaveEncashRepot(this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datepipe.transform(this.toDate, 'yyyy-MM-dd'), this.uniqueemployeeid, this.limitPara, this.offsetPara).subscribe(
          data => {
            // START onScrollLoadData
            this.leaveEncashmentList = data.encashableLeaveDetail;
            console.log(" data : ", this.leaveEncashmentList);

            this.uniqueemployeeid = null;
            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";

          }, error => {
            console.log(error);
            this.employeeMaster = new EmployeeMaster;
            (<HTMLInputElement>document.getElementById('employeename')).value = "";
          }
        );
      } else {
        if (this.uniqueemployeeid == null && employeename == null) {

          console.log(" check null or not ")
          this.alertmessages("Please Enter Employee Name or Unique Employee ID ", "danger");
        } else {
          console.log("uid / ename .....  not null  ")

          console.log("Unique Id : " + this.uniqueemployeeid);
          console.log("Employee Name  : " + employeename);
          if (Number(employeename)) {
            console.log("nametosearch  : " + employeename);

            this.uniqueemployeeid = employeename;
            employeename = null
          }
          this.empLeaveEncashReportService.getLeaveEncashRepot(this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datepipe.transform(this.toDate, 'yyyy-MM-dd'), this.uniqueemployeeid, this.limitPara, this.offsetPara).subscribe(
            data => {
              this.leaveEncashmentList = data.encashableLeaveDetail;
              console.log(" data : ", this.leaveEncashmentList);

              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";
            }, error => {
              console.log(error);
              this.employeeMaster = new EmployeeMaster;
              (<HTMLInputElement>document.getElementById('employeename')).value = "";
            }

          );
        }
      }
    }

  }

  getLeaveType() {
    console.log("get leave type ")
    this.employeeMasterservice.getLeaveType().subscribe(
      data => {
        this.LeaveTypeList = data;
        console.log("Leave types are  : ", this.LeaveTypeList)
      }, error => {
        console.log(error);
      }

    );
  }

  //   getemployeeinfo(empifo) {
  //     console.log("here info ... ")
  //     console.log(empifo)
  //     empifo.isedit = false;
  //     this.router.navigate(['/employeemaster'], { queryParams: empifo });
  //   }

  onScroll() {
    this.limitPara = this.limitPara + 5;
    this.searchLeaveEncashReport();
  }




  @HostListener('scroll', ['$event'])
  scrolled() {
    console.log("Scrole called >>")
    this.onScroll();
  }



  employeeMasterCSVDownload() {
    var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    this.empLeaveEncashReportService.downloadEncashmentReport().subscribe(data => {
      this.uniqueemployeeid = null;
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "EncashmentReport.xls";
      anchor.href = fileURL;
      anchor.click();
    },
      error => {
        console.log("Error Occured ", error);
      })
  }


  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }


  //   new
  checkFromAndToDate() {
    this.startDate = false;
    this.endDate = false;
    this.submitted = true;
    const currentDate = new Date();
    console.log("this.fromDate", this.fromDate);
    console.log("this.toDate ", this.toDate);
    if (this.toDate) {
      if (this.fromDate > this.toDate) {
        this.submitted = false
        this.endDate = true;
      } else {
        this.endDate = false;
        return true;
      }
    }
  }

  getEmployeeByNameOrId() {

    const [name, id] = this.employeename.split(' / ');
    this.queryName = name;
    console.log("Len ", this.query);
    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = []
    }
    if (this.queryName.length >= 3) {

      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data);
          this.employeeMasterLucene = data.employeeMasterData

          // if(this.employeeMaster.length > 0){
          // 	const autoCompleteId = document.getElementById("employeeMaster");
          // 	autoCompleteId.classList.remove("auto-complete-active");
          // }
        },
        error => {
          console.log("Error ", error);

        }
      )
    }
  }

  // getEmployeeByNameOrId() {
  //   console.log("Len ", this.employeename);
  //   // if (!isNaN(Number(this.query))) {
  //   if (this.employeename?.length <= 2) {
  //     console.log("number")
  //     // this.employeeMasterLucene = []
  //     this.empLeaveService.getEmployeeByNameOrUniqueId(this.employeename).subscribe(
  //       data => {
  //         console.log("Emp Data ", data);
  //         this.employeeMasterLucene = data.employeeMasterData

  //       },
  //       error => {
  //         console.log("Error ", error);
  //       })
  //   }
  //   // }
  //   else {
  //     if (this.query?.length >= 3) {
  //       this.empLeaveService.getEmployeeByNameOrUniqueId(this.employeename).subscribe(
  //         data => {
  //           console.log("Emp Data ", data);
  //           this.employeeMasterLucene = data.employeeMasterData

  //           // if(this.employeeMaster.length > 0){
  //           // 	const autoCompleteId = document.getElementById("employeeMaster");
  //           // 	autoCompleteId.classList.remove("auto-complete-active");
  //           // }
  //         },
  //         error => {
  //           console.log("Error ", error);

  //         }
  //       )
  //     }
  //   }
  // }

  limitpara() {
    this.offsetPara = 0;
  }

  clear() {


    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    this.employeename = "";
    this.leaveEncashmentList = null;
  }

}