import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class SalaryAdvanceService {

  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private httpclient: HttpClient, private commonService: CommonService) { }


  public saveSalaryAdvanceAppy(salAdvInfo: any): Observable<any> {

    console.log("save :", salAdvInfo)
    this.url = '/v1/applyForAdvance';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();

    return this.commonService.postWithHttpInfo(salAdvInfo, queryParameters, path);
  }

  public getAllAdvAppliesByUid(uid: any): Observable<any> {

    // console.log("save :", salAdvInfo)
    this.url = '/v1/getapplysForAdvance';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueemployeeid',uid);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path);
  }

  public getapplyAdvInfo(mid: any): Observable<any> {

    // console.log("save :", salAdvInfo)
    this.url = '/v1/getapplyAdvInfo';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('interMasterId',mid);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path);
  }

  public deleteAdvApplieByMid(mid: any): Observable<any> {

    // console.log("save :", salAdvInfo)
    this.url = '/v1/deleteApplyAdvInfo';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('interMasterId',mid);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path);
  }
}
