import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ExpenseType } from '../models/expenseType';
import { SessionService } from '../services';
import { SalaryAdvanceService } from '../services/salary-advance.service';
import { salaryAdvance } from '../models/salaryAdvance';

@Component({
  selector: 'app-salary-advance',
  templateUrl: './salary-advance.component.html',
  styleUrls: ['./salary-advance.component.css']
})
export class SalaryAdvanceComponent implements AfterViewInit {
  constructor(private salaryAdvanceService: SalaryAdvanceService, private sessionservice: SessionService) { }

  @ViewChild('salaryadvanceform') salaryAdvanceForm: NgForm;

  ngOnInit(): void {

    this.model.uniqueEmpId = this.sessionservice.getempid();
    this.submitted = false;
    this.clear();
    this.getAdvApplyHistory();
  }

  ngAfterViewInit() {
    this.clearForm();
  }


  amount: number;
  totalAmount: number;
  noOfInstallment: number;
  selectedList = new ExpenseType();
  expenseType: any[] = [];
  salaryAdvanceList: any[] = [];
  // salaryAdvance = salaryAdvance;

  date_of_loan: any;
  Ins_StartDate: any;
  model: any = {};
  isEdit: boolean = false;
  submitted: boolean;


  onDateChange(): void {
    this.setInsStartDate();

  }

  initialize() {
    this.totalAmount = null;
    this.noOfInstallment = null;
    this.date_of_loan = null;
    this.Ins_StartDate = null;
    this.model.installmentComp = [];
    this.isEdit = false;
  }

  calcInstallment() {
    console.log("uniqueEmpid", this.sessionservice.getempid())
    if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan) {

      this.setSalaryAdvanceModel();
      this.setInstallmentComp();

    }
  }

  editcalcInstallment() {
    console.log("uniqueEmpid", this.sessionservice.getempid())
    if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan) {

      this.setSalaryAdvanceModel();

    }
  }

  getAdvApplyHistory() {

    let uniqueEmpId = this.sessionservice.getempid();
    this.salaryAdvanceService.getAllAdvAppliesByUid(uniqueEmpId).subscribe(
      data => {
        console.log("Saved data : ", data)
        this.salaryAdvanceList = data.salaryAdvanceList;


      }, error => {

      });
  }

  validate(): any {
    if (this.totalAmount == null || this.totalAmount == undefined) {
      return false;
    }
    if (this.noOfInstallment == null || this.noOfInstallment == undefined) {
      return false;
    }
    if (this.date_of_loan == null || this.date_of_loan == undefined) {
      return false;
    }
    if (this.Ins_StartDate == null || this.Ins_StartDate == undefined) {
      return false;
    }
  }

  submit() {

    // console.log(this.salaryAdvanceForm);
    this.submitted = true;
    if (this.validate()) {

      this.salaryAdvanceService.saveSalaryAdvanceAppy(this.model).subscribe(
        data => {
          console.log("Saved data : ", data)
          this.getAdvApplyHistory();
          this.initialize();

        }, error => {

        });
    }


  }

  editSalaryAdvance(salAdvobj: any) {
    this.isEdit = true;
    this.totalAmount = salAdvobj.totalAmount;
    this.noOfInstallment = salAdvobj.noOfInstallment;
    const dbDate = salAdvobj.installStartDate.toString();
    console.log("dbDate", dbDate);
    this.Ins_StartDate = this.extractYearMonth(dbDate);

    const dOLDate = salAdvobj.dateOfLoan.toString();
    this.date_of_loan = this.extractYearMonth(dOLDate);

    this.salaryAdvanceService.getapplyAdvInfo(salAdvobj.interMasterId).subscribe(
      data => {
        let salaryAdvanceData = new salaryAdvance();
        salaryAdvanceData = data.salaryAdvance;
        this.model.installmentComp = salaryAdvanceData.installmentComp;
      },
      error => {
        const response = error.error;
        console.log(error);
      }
    );

  }

  setSalaryAdvanceModel() {
    this.model.totalAmount = this.totalAmount;
    this.model.noOfInstallment = this.noOfInstallment;

    let currentDate = new Date(this.Ins_StartDate);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-based, so add 1
    let day = currentDate.getDate().toString().padStart(2, '0');

    this.model.installStartDate = `${year}-${month}-${day}`;
    this.model.installStartMonth = this.Ins_StartDate;

    let currentDate2 = new Date(this.date_of_loan);
    let year2 = currentDate2.getFullYear();
    let month2 = (currentDate2.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-based, so add 1
    let day2 = currentDate2.getDate().toString().padStart(2, '0');

    this.model.dateOfLoan = `${year2}-${month2}-${day2}`;

    this.model.uniqueEmpId = this.sessionservice.getempid();
    console.log("")
  }

  delete(sal) {
    console.log("sal", sal);
    this.salaryAdvanceService.deleteAdvApplieByMid(sal.interMasterId).subscribe(
      data => {

        this.getAdvApplyHistory();
      },
      error => {
        const response = error.error;
        console.log(error);
      }
    );
  }



  setInstallmentComp() {
    // Ensure the correct date is used for the start
    let currentDate = new Date(this.Ins_StartDate);
    let year = currentDate.getFullYear();
    let totalInstallments = this.noOfInstallment;

    // Calculate installment amount
    let installAmt = (this.totalAmount / totalInstallments).toFixed(2);

    // Clear previous installments if needed
    this.model.installmentComp = [];

    for (let i = 0; i < totalInstallments; i++) {
      // Calculate new month and year
      let installmentDate = new Date(currentDate);
      installmentDate.setMonth(currentDate.getMonth() + i); // Increment month for each installment

      // Extract year, month, and day from the updated date
      year = installmentDate.getFullYear();
      let month = (installmentDate.getMonth() + 1).toString().padStart(2, '0');
      let day = installmentDate.getDate().toString().padStart(2, '0');

      // Format month name if needed (assuming `getMonthName` is defined elsewhere)
      let monthName = this.getMonthName(installmentDate.getMonth());

      // Add new installment to the list
      this.model.installmentComp.push({
        monthName: monthName, // or `${year}-${month}` if you need 'YYYY-MM'
        instAmount: installAmt,
        paidAmount: '',
        instDate: `${year}-${month}-${day}` // Format: 'YYYY-MM-DD'
      });
      console.log("monthName", monthName);
      console.log("`${year}-${month}-${day}`", `${year}-${month}-${day}`);
    }
  }

  setInsStartDate() {
    if (this.date_of_loan) {
      // Convert 'yyyy-MM' string to Date object
      const date = new Date(this.date_of_loan + '-01'); // Adding '-01' to form a valid Date

      // Add one month to the Date object
      date.setMonth(date.getMonth() + 1);

      // Format the updated date back to 'yyyy-MM' for the Ins_StartDate input
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month
      const year = date.getFullYear();

      // Set Ins_StartDate in 'yyyy-MM' format
      this.Ins_StartDate = `${year}-${month}`;
    }
  }


  clear() {
    this.totalAmount = null;
    this.noOfInstallment = null;
    this.date_of_loan = null;
    this.Ins_StartDate = null;
    this.model.installmentComp = [];
    this.isEdit = false;
    console.log("salaryAdvanceForm",this.salaryAdvanceForm);
    // this.salaryAdvanceForm.reset();
  }

  clearForm() {
    if (this.salaryAdvanceForm) {
      console.log("Resetting form", this.salaryAdvanceForm);
      this.salaryAdvanceForm.reset(); // Reset form only after view initialization
    } else {
      console.log("Form is not initialized yet.");
    }
  }

  getMonthName(monthNumber: number): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // // Validate monthNumber to be within the valid range (1-12)
    // if (monthNumber < 1 || monthNumber > 12) {
    //   throw new Error("Invalid month number. It must be between 1 and 12.");
    // }

    return monthNames[monthNumber]; // Subtract 1 because array is 0-based
  }
  readamount(e, item) {
    this.amount = e.target.value;
    console.log(this.amount, "amount and bool")
    if (this.amount >= 0) {
      item.InstAmount = Number(this.amount);
    }
  }

  changeAmount(e, item, i) {
    let amount = e.target.value;

    let sub = this.totalAmount - amount;
    let noi = this.noOfInstallment - 1;
    let rem = (sub / noi).toFixed(2);
    // for (let j = 0; i < this.noOfInstall; j++) {
    //   if(j==i){
    //     this.model.InstallmentComp[j].InstAmount = amount;
    //   }else{
    //     this.model.InstallmentComp[j].InstAmount = rem;
    //   }

    // }
  }

  extractYearMonth(dateString: string): string {
    const date = new Date(dateString); // Convert to a Date object
    const year = date.getFullYear();   // Get the year
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (zero-based)

    return `${year}-${month}`; // Return in 'YYYY-MM' format
  }

}
