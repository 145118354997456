import { Component, OnInit } from '@angular/core';
import { ExtraTimeOut } from '../models/extra-time-out';
import { Extratimeoutservices } from '../services/extratimeoutservices';
import { EmployeeattendenceserviceService } from '../services/employeeattendenceservice.service';
declare var $: any;

@Component({
  selector: 'app-employee-overtime',
  templateUrl: './employee-overtime.component.html',
  styleUrls: ['./employee-overtime.component.css']
})
export class EmployeeOvertimeComponent implements OnInit {

  overtime = new ExtraTimeOut(-1, -1, " ", " ", " ", " ", " ", " ", " ", " ");
  uniqueEmployeeId = Number(sessionStorage.getItem('empId'));

  date: any;
  totalMinutes: any;
  fromTime: any;
  toTime: any;
  reason: any = '';
  OverTimeOutlist: any[] = [];

  deleteovertime: ExtraTimeOut;
  overtimeAppliedId: number;
  // empAttendenceService: any;


  constructor(private extraTimeOutService: Extratimeoutservices, private empAttendenceService: EmployeeattendenceserviceService) { }

  ngOnInit(): void {
    this.getOverTimeOutApplied();
  }

  dateFocus() {
    this.checkTotalHourWorked();
  }

  checkTotalHourWorked() {
    console.log(" check total hour worked for date :", this.date);
    this.totalMinutes = undefined;
    this.fromTime = undefined;
    this.toTime = undefined;
    console.log(" brfore ");
    console.log("totalMinutes :", this.totalMinutes, " fromTime :", this.fromTime, " toTime :", this.toTime);

    this.empAttendenceService.getEmpOvertimeSummary(this.uniqueEmployeeId, this.date, this.date).subscribe(
      data => {
        if (data.attendanceSummary.length !== 0) {
          this.fromTime = data.attendanceSummary[0].firstIN;
          this.toTime = data.attendanceSummary[0].lastOUT;
          this.totalMinutes = data.attendanceSummary[0].totalSwipeHour;
          console.log("after api call  ");
          console.log("totalHour :", this.totalMinutes, " inTime :", this.fromTime, " outTime :", this.toTime);
          this.totalMinutes = this.totalMinutes.substring(0, 5);
          this.totalMinutes = this.totalMinutes + ' Hr';
          console.log("this total hour slice ed total hour  : ", this.totalMinutes);
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  uniqueEmpId(uniqueEmpId: any, date: any, date1: any) {
    throw new Error('Method not implemented.');
  }

  validate(): boolean {
    debugger
    if (this.date != "" && this.reason != "" && this.fromTime != "" && this.toTime != "") {
      return true;
    } else {
      this.alertmessages("Can't apply OT details, please check all the required fields.", "danger")
      return false;
    }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
       <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 5000);
  }

  submit() {
    if (this.validate()) {
      console.log("submit ");
      this.overtime.uniqueEmployeeId = this.uniqueEmployeeId;
      this.overtime.date = this.date;
      this.overtime.totalMinutes = this.totalMinutes;
      this.overtime.fromTime = this.fromTime;
      this.overtime.toTime = this.toTime;
      console.log("Reason");

      this.overtime.reason = this.reason.trim();
      console.log("this.uniqueEmployeeId ", this.uniqueEmployeeId);
      console.log("overtime ", this.overtime);
      console.log("this.overtime.totalMinutes ", this.overtime.totalMinutes);

      this.extraTimeOutService.saveoverTime(this.overtime).subscribe(
        data => {
          console.log("data ", data);
          console.log("data Message", data.successMessages[0]['key']);
          if (data.successMessages[0]['key'] == 'Applied Extra Time Out Successfully Submitted') {
            this.alertmessages("Successfully Submitted", "success")
          } else {
            this.alertmessages("Could Not Apply, No Sufficient overtime Balance", "danger")
          }

          this.clear();
          this.getOverTimeOutApplied();
        },
        error => {
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            let err: string = response.errorMessages[0]['key'];
            if (err.toLocaleLowerCase() === "AlreadyExistsException".toLocaleLowerCase()) {
              this.alertmessages("A over time out request has already been made for the selected date.", "danger")
            }
          }
          this.clear()
        }
      )
    }

  }

  clear() {
    this.date = "";
    this.totalMinutes = "";
    this.fromTime = "";
    this.toTime = "";
    this.reason = "";
  }

  getOverTimeOutApplied() {
    // get method 
    this.extraTimeOutService.getoverTimeApplied(this.uniqueEmployeeId).subscribe(
      data => {

        console.log("over time",data);
        this.OverTimeOutlist = data.extraTimeOutApplied;
      }, error => {
        console.log(error);
      }
    )
  }

  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.deleteovertime = info;
    this.deleteovertime.uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
    $('#showAlertVer').modal('toggle');
  }

 confirmDelete() {
  if (this.deleteovertime.ExtraTimeOutAppliedId == -1) {
    this.alertmessages("Try again", "danger")
    return;
  }
  this.extraTimeOutService. deleteoverTimeApplied (this.deleteovertime).subscribe({
    next: (data) => {
      this.alertmessages(data.successMessages[0]['key'], "success")
      this.getOverTimeOutApplied();
    },
    error: (error) => {
      console.log("Error ", error);
      this.alertmessages("Not Deleted", "danger")
    },
    complete: () => {
      this.closeMRQModal();
    }
  })
}
 


   
 closeMRQModal() {
 $('#showAlertVer').modal('toggle');
 
 }
}
