import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ExtraTimeOut } from '../models/extra-time-out';
import { EmployeeMaster } from '../models/employeeMasterts';
import { Extratimeoutservices } from '../services/extratimeoutservices';
import { ActivatedRoute } from '@angular/router';
import { EmployeeLeaveService } from '../services/employee-leave.service';

declare var $: any;
@Component({
  selector: 'app-extra-timeout-report',
  templateUrl: './extra-timeout-report.component.html',
  styleUrls: ['./extra-timeout-report.component.css']
})

export class ExtraTimeoutReportComponent implements OnInit {

	orderBy: string = 'employeeName';
	query: any="";
	queryName: any="";
	fromDate: string;
	toDate: string;
	status: string;
	limitPara: any;
	offsetPara: any;
	title: string;
	employeesETOSummary: ExtraTimeOut[];
	employeeMaster: EmployeeMaster[];
	statusValue: string = 'All';
	existingquery:string;
	constructor(private empLeaveService: EmployeeLeaveService,private extraTimeOutService: Extratimeoutservices, private route: ActivatedRoute, private render: Renderer2,
		) {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth() + 1;
		this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
		const lastDay = new Date(year, month, 0).getDate();
		this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
		this.title = "Extra Time Out Summary";
	}

	sortTableData() {
		// Assuming employeesETOSummary is the array you want to sort

		this.employeesETOSummary.sort((a, b) => {
			if (this.orderBy === 'employeeId') {
				return a.uniqueEmployeeId.toString().localeCompare(b.uniqueEmployeeId.toString());
			} else if (this.orderBy === 'employeeName') {
				return a.employeename.localeCompare(b.employeename);
			} else if (this.orderBy === 'date') {
				return new Date(a.date).getTime() - new Date(b.date).getTime();
			}
			// Default case if orderBy is not recognized
			return 0;
		});
	}

	ngOnInit(): void {
		
		this.employeesETOSummary = new Array<ExtraTimeOut>();
		// this.getAllExtraTimeOut();
		this.limitPara = 13;

		this.offsetPara = 0;
		this.sortTableData();
		(<HTMLInputElement>document.getElementById('employeename')).focus();

		this.route.queryParams.subscribe(params => {

			this.status = params.leaveStatus;
			if (this.status && this.status.trim().length !== 0) {
				this.onStatusChange(this.status)
				this.getFilteredETOSummary()
			}
		});
		console.log("parameter passed from dashboard to leave status");

		this.render.listen(document.getElementById('navigateclient'), 'click', () => {
			$('html,body').animate({
				scrollTop: $(`#add-update-client`).offset().top - 90
			},
				'slow');
		})

	}

	getFilteredETOSummary() {
		const [name, id] = this.query.split(' / ');

    let selectedName =name;
    let selecteduniqueeEmpId =id;
	console.log("selecteduniqueeEmpId",selecteduniqueeEmpId);
		console.log("fromDate: ", this.fromDate, "toDate: ", this.toDate, "page limit para :", this.limitPara, "page limit offset :", this.offsetPara);
		console.log(this.statusValue);
		let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
		if (!employeename) {
			employeename = null;
		} else {
			employeename = employeename.trim()
		}
		if (!selecteduniqueeEmpId) {
			selecteduniqueeEmpId = 0;
		} else {
			selecteduniqueeEmpId = id;
		}
		console.log("Employee " + employeename);
		this.extraTimeOutService.getFilteredETOSummary(selecteduniqueeEmpId, this.fromDate, this.toDate, this.statusValue, this.limitPara, this.offsetPara).subscribe(
			data => {
			
				console.log("Backend Leave Data ", data);
				this.scrollLength()
			
				if (this.offsetPara == 0) {
					this.employeesETOSummary = data.extraTimeOutApplied;

				} else {
					this.employeesETOSummary = this.employeesETOSummary.concat(data.extraTimeOutApplied);
				}

				console.log("Employee ETO Summary ", this.employeesETOSummary);
				this.sortTableData();
				this.existingquery = employeename;
			},
			error => {
				console.log("Error ", error);
				(<HTMLInputElement>document.getElementById('employeename')).value = "";
			}
		)
	}

	scrollLength() {
		console.log("dynamic height on scroll ");
		console.log("");
		const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
		const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
		const computedHeight = height.match(regex);
		console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
		if (computedHeight && Number(computedHeight[1]) < 450) {
			let value: any = Number(computedHeight[1]);
			console.log("default value = ", value)
			value = value + (2 * 55);
			value = value + "px";
			console.log("final vl:", value)
			console.log("transition")
			document.getElementById("scrolllength").style.maxHeight = value
		}
		console.log("");
	}

	onStatusChange(selectedValue: string) {
		this.statusValue = selectedValue;
		console.log("Value", this.statusValue)
	}


	getEmployeeByNameOrId() {

		this.query = this.query.trim();
		const [name, id] = this.query.split(' / ');
		this.queryName = name;
		console.log("Len ", this.query);
		if (this.query.length <= 2) {
			this.employeeMaster = []
		}
		if (this.query.length >= 3) {

			this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
				data => {
					console.log("Emp Data ", data);
					this.employeeMaster = data.employeeMasterData
					console.log("this.employeeMaster", this.employeeMaster);
				
				},
				error => {
					console.log("Error ", error);

				}
			)
		}
	}


	onScroll() {
		this.offsetPara = this.offsetPara + 13;
		console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
		this.getFilteredETOSummary();

	}

	limitpara() {
		this.offsetPara = 0;
		console.log(" on clikc limit para ", this.limitPara)
		console.log("this offset", this.offsetPara)
	}

	@HostListener('scroll', ['$event'])
	scrolled() {

		console.log("Scrolled >>> ")
		this.onScroll();
	}


	extraTimeOutReportCSVDownload(employeesETOSummary: any) {
		console.log("employeesETOSummary",employeesETOSummary);
		
		this.extraTimeOutService.downloadETOReportCSVFile(employeesETOSummary).subscribe((data) => {
		  console.log(data)
		  var file = new Blob([data], { type: 'application/vnd.ms-excel' });
		  var fileURL = URL.createObjectURL(file);
		  var anchor = document.createElement("a");
		  anchor.download = "ExtraTimeOutReport.xls";
		  anchor.href = fileURL;
		  anchor.click();
		  
		  },
		  (error) => {
			console.log("Error Occured ", error);
		  }
		);
	  }

}
