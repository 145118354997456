import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SalaryDetailMaster, SalaryMaster } from 'src/app/models/SalaryMaster';
import { EmpsalaryslipService } from 'src/app/services/empsalaryslip.service';

@Component({
  selector: 'app-salary-detail',
  templateUrl: './salary-detail.component.html',
  styleUrls: ['./salary-detail.component.css']
})
export class SalaryDetailComponent implements OnInit {
  @ViewChild('salaryDetailForm') salaryDetailForm: NgForm;

  // here we cllect all the response from api for view 
  salaryDetail = new SalaryDetailMaster();
  // here for droop down to filter to remove duplicate 
  salaryDetailForDropDown = new SalaryDetailMaster();

  addOrUpdate: boolean = true;
  add_view_toggle: string = 'viewSalaryDetail';
  insertDetailMaster;

  constructor(private empsalaryservice: EmpsalaryslipService) { }

  ngOnInit(): void {
    this.insertDetailMaster = new SalaryDetailMaster();
    this.getSalaryDetail();
    this.clear();
  }

  getSalaryDetail() {
    this.empsalaryservice.getSalaryDetailsD().subscribe(
      (data) => {
        console.log("salary details ", data)
        this.salaryDetail = data.salaryDetailMaster;
        // get and set response from api , here we get indiviadul detail with head 
        // so it will populte head multipe time has it wil have diferent detail attached to it 
        // either have a different varable with head filter so that it will not confuse that user in head selection in salary detail master screen 
        this.salaryDetailForDropDown = data.salaryDetailMaster.filter((item, index, self) => index === self.findIndex(t => t.salaryHeadId === item.salaryHeadId));

        console.log("salarydetail  for view ", this.salaryDetail);
        console.log("for drop down in detail master ", this.salaryDetailForDropDown);
      }, (error) => {
        console.log(error)
      }
    )
  }

  addSalaryDetailButton() {
    this.add_view_toggle = 'addSalaryDetail'; // toggle button and clear all input fields 
    // this.clear();
  }

  updateSalaryDetail() { // update the new value , here pass ther updated payment months 

    if (this.insertDetailMaster.salaryDetailName == undefined || this.insertDetailMaster.salaryDetailName.trim().length == 0 || this.insertDetailMaster.salaryDetailName == null) {
      this.alertmessages("Please enter detail name", "danger");
      return;
    }

    if (this.insertDetailMaster.salaryHeadId == undefined || this.insertDetailMaster.salaryHeadId == null) {
      this.alertmessages("Please select head name", "danger");
      return;
    }

    if (this.insertDetailMaster.useForCalcIndic == undefined || this.insertDetailMaster.useForCalcIndic == null || this.insertDetailMaster.useForCalcIndic.trim().length == 0) {
      this.alertmessages("Please select calculation indic type", "danger");
      return;
    }

    this.empsalaryservice.updateSalaryDetailMaster(this.insertDetailMaster).subscribe((data) => {
      this.alertmessages("Salary Detail Updated sucessfully  ", "success");
      this.clear();
      this.getSalaryDetail();
      this.viewSalaryDetailButton();
    }, (error) => {
      console.log(error);
      this.alertmessages("Please Try Again", "danger");
    });

  }

  viewSalaryDetailButton() {
    this.add_view_toggle = 'viewSalaryDetail'; // switch the button to view and show add button and clear old record is exist 
    this.addOrUpdate = true;
    this.clear();
  }

  submit() {  // save the template form 
    console.log("save ", this.insertDetailMaster);
    if (this.insertDetailMaster.salaryDetailName == undefined || this.insertDetailMaster.salaryDetailName.trim().length == 0 || this.insertDetailMaster.salaryDetailName == null) {
      this.alertmessages("Please enter detail name", "danger");
      return;
    }

    if (this.insertDetailMaster.salaryHeadId == undefined || this.insertDetailMaster.salaryHeadId == null) {
      this.alertmessages("Please select head name", "danger");
      return;
    }

    if (this.insertDetailMaster.useForCalcIndic == undefined || this.insertDetailMaster.useForCalcIndic == null || this.insertDetailMaster.useForCalcIndic.trim().length == 0) {
      this.alertmessages("Please select calculation indic type", "danger");
      return;
    }

    if ((this.insertDetailMaster.salaryDetailName != undefined || this.insertDetailMaster.salaryDetailName.trim().length > 0) && this.insertDetailMaster.salaryHeadId != undefined) {
      this.empsalaryservice.saveSalaryDetailMaster(this.insertDetailMaster).subscribe(
        (data) => {
          console.log(data);
          this.alertmessages("Salary Detail added sucessfully  ", "success");
          this.getSalaryDetail();
          this.viewSalaryDetailButton();
          this.clear();
        }, (error) => {
          console.log(error);
          this.alertmessages("Please Try Again", "danger");
        }
      );
    }
  }

  editSalaryDetail(info: any) { // edit button value set 
    this.addOrUpdate = false;
    this.add_view_toggle = 'addSalaryDetail';

    this.insertDetailMaster.salaryHeadName = info.salaryHeadName;
    this.insertDetailMaster.salaryHeadId = info.salaryHeadId;
    this.insertDetailMaster.salaryDetailName = info.salaryDetailName;
    this.insertDetailMaster.salaryDetailId = info.salaryDetailId;
    this.insertDetailMaster.useForCalcIndic = info.useForCalcIndic;
    this.insertDetailMaster.esicreteria = info.esicreteria;
    this.insertDetailMaster.leaveEncashment = info.leaveEncashment;
    this.insertDetailMaster.generalHolidayCal = info.generalHolidayCal;
    this.insertDetailMaster.taxableSalary = info.taxableSalary;
    this.insertDetailMaster.recoveryComp = info.recoveryComp;
    this.insertDetailMaster.orderSalaryComponent = info.orderSalaryComponent;
    this.insertDetailMaster.includeGross = info.includeGross;
    this.insertDetailMaster.eligibleDa = info.eligibleDa;
    this.insertDetailMaster.typeOfComponent = info.typeOfComponent;
    this.insertDetailMaster.systemComponent = info.systemComponent;
    this.insertDetailMaster.hraIsApplicable = info.hraIsApplicable;
    this.insertDetailMaster.Remove_from_sal_if = info.Remove_from_sal_if;
    this.insertDetailMaster.availablemonths = info.availablemonths;
    this.insertDetailMaster.paymentmonths = info.paymentmonths;
    console.log(" paymentmonths months ", this.insertDetailMaster.paymentmonths);
    this.insertDetailMaster.deductionSec = info.deductionSec;
  }

  isChecked(value: number): boolean {
    console.log("is checked or not ");
    return this.insertDetailMaster.paymentmonths.includes(value);
  }

  onCheckboxChange(event: Event, value: number): void {
    console.log("check box changes ");
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      if (!this.insertDetailMaster.paymentmonths.includes(value)) {
        this.insertDetailMaster.paymentmonths.push(value);
      }
    } else {
      const index = this.insertDetailMaster.paymentmonths.indexOf(value);
      if (index !== -1) {
        this.insertDetailMaster.paymentmonths.splice(index, 1);
      }
    }
    console.log("updated value in paymentmnths ", this.insertDetailMaster.paymentmonths);
  }

  deleteSalaryDetail(detailid: any, headid: any) {
    if (detailid != null && detailid != undefined) {
      this.empsalaryservice.deleteSalaryDetail(detailid, headid).subscribe((date) => {
        this.alertmessages("Successfully deleted ", "success");
        this.getSalaryDetail();
      }, (error) => {
        this.alertmessages("Salary Detail is in use", "danger");
      });
    }

  }

  clear() {
    console.log("clear ");

    this.insertDetailMaster.salaryHeadName = '';
    this.insertDetailMaster.salaryHeadId = '';
    this.insertDetailMaster.salaryDetailName = '';
    this.insertDetailMaster.useForCalcIndic = '';
    this.insertDetailMaster.esicreteria = false;
    this.insertDetailMaster.leaveEncashment = false;
    this.insertDetailMaster.generalHolidayCal = false;
    this.insertDetailMaster.taxableSalary = false;
    this.insertDetailMaster.recoveryComp = false;
    this.insertDetailMaster.orderSalaryComponent = '';
    this.insertDetailMaster.includeGross = false;
    this.insertDetailMaster.eligibleDa = false;
    this.insertDetailMaster.typeOfComponent = '';
    this.insertDetailMaster.systemComponent = '';
    this.insertDetailMaster.hraIsApplicable = false;
    this.insertDetailMaster.Remove_from_sal_if = false;
    this.insertDetailMaster.availablemonths = [];
    this.insertDetailMaster.paymentmonths = [];
    this.insertDetailMaster.deductionSec = '';
    this.salaryDetailForm.form.reset();
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  months = [
    { name: 'Jan', value: 1 },
    { name: 'Feb', value: 2 },
    { name: 'Mar', value: 3 },
    { name: 'Apr', value: 4 },
    { name: 'May', value: 5 },
    { name: 'Jun', value: 6 },
    { name: 'Jul', value: 7 },
    { name: 'Aug', value: 8 },
    { name: 'Sep', value: 9 },
    { name: 'Oct', value: 10 },
    { name: 'Nov', value: 11 },
    { name: 'Dec', value: 12 }
  ];

  onChange(info: any) {
    console.log("change in fixed component ", this.insertDetailMaster.typeOfComponent);
    if (this.insertDetailMaster.typeOfComponent !== 'System Calculated Component') {
      const element = document.getElementById('SYSTEMCOMPONENT');
      this.insertDetailMaster.systemComponent = '';
      element.classList.add('disable');
      console.log("add disable ");

    } else {
      const element = document.getElementById('SYSTEMCOMPONENT');
      console.log("add disable ");
      element.classList.remove('disable');
    }
  }

}
