import { Component, OnInit } from '@angular/core';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { EmployeeLeaveService } from 'src/app/services/employee-leave.service';
import { EmpsalaryslipService } from 'src/app/services/empsalaryslip.service';

@Component({
  selector: 'app-salary-report-bank',
  templateUrl: './salary-report-bank.component.html',
  styleUrls: ['./salary-report-bank.component.css']
})
export class SalaryReportBankComponent implements OnInit {

  constructor(private empsalaryservice: EmpsalaryslipService,private empLeaveService: EmployeeLeaveService) { }

  ngOnInit(): void {

    const currentMonthValue = new Date().getMonth() + 1; // getMonth() returns 0 for January, so +1
    this.selectedMonth = this.months.find(month => month.value === currentMonthValue);

    const currentYear = new Date().getFullYear();
    // this.years.push(''); // Add empty option
    for (let year = currentYear; year >= 2018; year--) {
      this.years.push(year.toString());
    }
    this.year = currentYear.toString();
    // this.getEmployeeList();
  }

  months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  selectedMonth: { name: string, value: number } | undefined;
  years: string[] = [];
  directoryPath: string = '';
  year: any;
  selecteduniqueeName: any;
  selecteduniqueeEmpId:any;
  employeeList: any[] = [];
  query: any;
  queryName: any;
  employeeMasterLucene: EmployeeMaster[];

  // getEmployeeByNameOrId() {
    
  //   const [name, id] = this.query.split(' / ');
  //   this.queryName = name;
  //   console.log("Len ", this.query);
  //   if (this.queryName.length <= 2) {
  //     this.employeeMasterLucene = []
  //   }
  //   if (this.queryName.length >= 3) {

  //     this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
  //       data => {
  //         console.log("Emp Data ", data);
  //         this.employeeMasterLucene = data.employeeMasterData
  //       },
  //       error => {
  //         console.log("Error ", error);

  //       }
  //     )
  //   }
  // }
  downloadFile() {

    if(!this.year){
      console.log('year',this.year);
      return;
    }
    // if(this.selectedMonth.value.i)
    if (!this.selectedMonth || !this.selectedMonth.name || !this.selectedMonth.value) {
      console.log('month',this.selectedMonth);
      return;
    }

    this.empsalaryservice.downloadSalReportBank(this.year,this.selectedMonth.value.toString(),this.selectedMonth.name.toString(),this.selecteduniqueeEmpId).subscribe(
      (data) => {

        if (!data) {
          this.alertmessages('No data available for the selected year and month.', 'danger');
          return;
      }
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "salaryReport-Bank"+this.selectedMonth.name.toString()+this.year+".xls";
      anchor.href = fileURL;
      anchor.click();
      
      },
      (error) => {
        if (error.status === 204) {
          this.alertmessages('No data available for the selected year and month.', 'danger');
      } else {
          this.alertmessages('Error occurred while downloading the report.', 'danger');
      }
        console.log("Error Occured ", error);
      }
    );
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
}
