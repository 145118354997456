import { Component, OnInit } from '@angular/core';
import { LeaveWorkFlow } from '../models/leaveWorkflow';
import { TaskDetailsForLeave } from '../models/task-details-for-leave.model';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { ApproveLeavePipe } from '../pipes/approve-leave.pipe';
import { TitleCasePipe } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-approve-comp-off',
  templateUrl: './approve-comp-off.component.html',
  styleUrls: ['./approve-comp-off.component.css']
})
export class ApproveCompOffComponent implements OnInit {
  private APPROVED: string = "APPROVED";
  private REJECTED: string = "REJECTED";
  private LEAVE_APPROVE_PENDING: string = "Leave Approve Pending"
  private LEAVE_CANCEL_PENDING: string = "Leave Cancel Pending"
  private LEAVE_CANCEL_APPROVED: string = "Leave Cancel Approved"
  private LEAVE_CANCEL_REJECTED: string = "Leave Cancel Rejected"
  searchleave: any;

  taskDetailsForLeave: TaskDetailsForLeave[] = [];
  leaveDetailFlow: LeaveWorkFlow[] = []
  leaveTypesList = [
    { leaveType: "", leaveName: "" }
  ]
  leaveTypeMap = new Map();
  approvalLeaves: TaskDetailsForLeave[] = [];
  leavesApproval: LeaveWorkFlow[] = [];
  templeaveDetailFlow: LeaveWorkFlow[] = [];
  statusFilter: any = 'all';

  constructor(private empLeaveService: EmployeeLeaveService, private approve: ApproveLeavePipe, private titleCase: TitleCasePipe) { }

  ngOnInit(): void {
    if (localStorage.getItem('navigateDashboard') == 'true') {
      this.search();
      localStorage.setItem('navigateDashboard', 'false');
    }
  }

  search() {
    this.getLeaves();
    this.getCompOffForApproval();
  }

  getLeaves() {
    this.empLeaveService.getAllLeaves().subscribe(
      data => {
        this.leaveTypesList = data.leaveMasters
        this.setLeaveMap();
      },
      error => {
        console.log("Error ", error);
      }
    )
  }

  showTable = false
  getCompOffForApproval() {
    this.leaveDetailFlow = [];
    this.templeaveDetailFlow = [];
    this.empLeaveService.getCompOffForApproval().subscribe(
      data => {
        console.log("get Leave for Approval ");
        if (this.statusFilter == 'all') {
          console.log("this.statusFilter ", this.statusFilter);
          this.leaveDetailFlow = data.leaveWorkflow;
          this.leaveDetailFlow.forEach(item => {
            item.employeeLeaveApplied.leaveStatus = this.titleCase.transform(item.employeeLeaveApplied.leaveStatus);
          })
          this.templeaveDetailFlow = this.leaveDetailFlow;
        } else {
          console.log("this.statusFilter ", this.statusFilter);
          this.leaveDetailFlow = data.leaveWorkflow;
          this.leaveDetailFlow.forEach(item => {
            item.employeeLeaveApplied.leaveStatus = this.titleCase.transform(item.employeeLeaveApplied.leaveStatus);
          })
          this.leaveDetailFlow = this.leaveDetailFlow.filter(
            ele => ele.employeeLeaveApplied.leaveStatus == this.statusFilter
          );
          this.templeaveDetailFlow = this.leaveDetailFlow;
        }
        if (this.leaveDetailFlow.length > 0) {
          this.showTable = true
        }
        console.log("leave response ", this.leaveDetailFlow);
        $('html,body').animate({
          scrollTop: $(`#navigatedest`).offset().top - 90
        },
          'slow');
      },
      error => {
        console.error("Error while getting Leave For Approval ", error);
      }
    )
  }

  setLeaveMap() {
    this.leaveTypesList.forEach(type => {
      this.leaveTypeMap.set(type.leaveType, type.leaveName)
    })
  }

  getLeaveName(leaveType: any) {
    return this.leaveTypeMap.get(leaveType)
  }

  checkAllSelected(e: any) {
    console.log("checkAllSelected ");

    const checkboxes = document.querySelectorAll('input');
    if (e.target.checked) {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = true;
        }
      }
      this.leavesApproval.splice(0, this.leavesApproval.length)
      console.log(" select all ", this.leavesApproval);

      if (this.searchleave) {
        this.searchLeaves.forEach(task => {
          this.leavesApproval.push()
        });
      } else {
        this.leaveDetailFlow.forEach(task => {
          this.leavesApproval.push(task)
        });
      }
      console.log(" check  all ", this.leavesApproval.length);
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = false;
        }
      }
      this.leavesApproval.splice(0, this.leavesApproval.length);
      console.log(" end  ", this.leavesApproval.length);
    }
  }

  checkSelected(leave: any, e: any) {
    console.log("check selected ");

    if (e.target.checked) {
      console.log(" before ", this.leavesApproval.length);
      console.log(this.leavesApproval);
      this.leavesApproval.push(leave);
      console.log("after ", this.leavesApproval.length);
      console.log(this.leavesApproval);
    } else {
      console.log("else part before ", this.leavesApproval.length);
      console.log(this.leavesApproval);
      this.leavesApproval = this.leavesApproval.filter(item => item.employeeLeaveApplied.employeeLeaveAppliedId !== leave.employeeLeaveApplied.employeeLeaveAppliedId);
      console.log("else part after ", this.leavesApproval.length);
      console.log(this.leavesApproval);
    }
    console.log("check selected done ");
  }

  onApproveHandler() {
    let approve_reject = true;
    let flag = true
    if (this.leavesApproval.length === 0) {
      this.alertmessages("Please select item for Approval.", "danger")
      flag = false
      return;
    }

    if (flag) {
      this.empLeaveService.compOffForApproval(this.leavesApproval, approve_reject).subscribe(
        data => {
          this.alertmessages("Successfully Approved.", "success")
          this.leavesApproval.splice(0, this.leavesApproval.length)
          this.searchleave = ""
          this.resetAllCheckBoxes()
          this.getCompOffForApproval()
        },
        error => {
          this.searchleave = ""
          console.error("Error ", error);
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            let err: string = response.errorMessages[0]['key'];
            if (err.toLocaleLowerCase() === "FlowableObjectNotFoundExceptionSingle".toLocaleLowerCase()) {
              this.alertmessages("A requests have already been processed.", "danger")
            } else if (err.toLocaleLowerCase() === "FlowableObjectNotFoundException".toLocaleLowerCase()) {
              this.alertmessages("One or more requests have already been processed.", "danger")
            } else {
              this.alertmessages("Not Approved.", "danger")
            }
          } else {
            this.alertmessages("Not Approved.", "danger")
          }
          this.resetAllCheckBoxes()
          this.leavesApproval.splice(0, this.leavesApproval.length)
          this.getCompOffForApproval()
        }
      )
    }
  }

  onRejectHandler() {
    let approve_reject = false;
    let flag = true
    if (this.leavesApproval.length === 0) {
      this.alertmessages("Please select item for Rejection.", "danger")

      flag = false
      return;
    }

    let isCommentAbsent = false;
    this.leavesApproval.forEach(rejectLeave => {

      if (rejectLeave.employeeLeaveApplied.remark === undefined) {
        console.log("Reject extra time out ");
        isCommentAbsent = true;
        flag = false
        return;
      }
    })

    if (isCommentAbsent) {
      this.alertmessages("Comments are mandatory for Rejection.", "danger")
      return;
    }

    if (flag) {
      this.empLeaveService.compOffForApproval(this.leavesApproval, approve_reject).subscribe(
        data => {
          this.alertmessages("Successfully Rejected.", "success")
          this.leavesApproval.splice(0, this.leavesApproval.length)
          this.searchleave = ""
          this.resetAllCheckBoxes()
          this.getCompOffForApproval()
        },
        error => {
          this.searchleave = ""
          console.error("Error ", error);
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            let err: string = response.errorMessages[0]['key'];
            if (err.toLocaleLowerCase() === "FlowableObjectNotFoundExceptionSingle".toLocaleLowerCase()) {
              this.alertmessages("A requests have already been processed.", "danger")
            } else if (err.toLocaleLowerCase() === "FlowableObjectNotFoundException".toLocaleLowerCase()) {
              this.alertmessages("One or more requests have already been processed.", "danger")
            } else {
              this.alertmessages("Not Rejected.", "danger")
            }
          } else {
            this.alertmessages("Not Rejected.", "danger")
          }

          this.leavesApproval.splice(0, this.leavesApproval.length)
          this.resetAllCheckBoxes()
          this.getCompOffForApproval()
        }
      )

    }
  }

  searchLeaves: LeaveWorkFlow[] = [];
  chkInput(e: any) {
    console.log("chkinput ", this.searchleave);
    if (this.searchleave.length > 3) {
      console.log("call filter function ");
      this.leaveDetailFlow = this.leaveDetailFlow.filter(item =>
        item.name.toLowerCase().includes(this.searchleave.toLowerCase())
      );
      console.log(this.leaveDetailFlow);
    }
    else {
      this.leaveDetailFlow = this.templeaveDetailFlow;
    }
  }

  resetAllCheckBoxes() {

    const checkboxes = document.querySelectorAll('input');
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == 'checkbox') {
        checkboxes[i].checked = false;
      }
    }
    this.searchleave = ""
    this.leavesApproval.splice(0, this.leavesApproval.length)
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 5000);
  }
}
