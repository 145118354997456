export class ExtraTimeOut {
  constructor(
    public uniqueEmployeeId: number,
    public ExtraTimeOutAppliedId: number,
    public employeeId: string,
    public employeename: string,
    public createdDate: string,
    public date: string,
    public totalMinutes: string,
    public fromTime: string,
    public toTime: string,
    public reason: string,
  ) { }
}
