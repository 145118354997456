import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PfReportService {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;
  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  

  public getPfstatementReport(year:any,monthValue:any,monthname:any,  limitPara?:number , offset?:number): Observable<any> {
    this.url = '/v1/getpfstatementReport';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('year', year);
        queryParameters = queryParameters.set('monthValue', monthValue);
        queryParameters = queryParameters.set('monthName', monthname);
        queryParameters = queryParameters.set('limit',limitPara);
        queryParameters = queryParameters.set('offset',offset);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

}
